import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import stbfullImg from "../../images/projectsfull/tenboogaerdefull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Salons Ten Boogaerde</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="Salons Ten Boogaerde"
              projectfulldescription="Salons Ten Boogaerde is a restaurant and event space that wanted a website to showcase their different venues, events and services. When designing this website I made sure to guard their brand identity and convey the right atmosphere."
              projectclient="Salons Ten Boogaerde"
              projectrole="Web Design & Front-end Development"
              projectdate="2018"
              projectfullimg={<div className="project--img--inner"><img data-src={stbfullImg} className="project--img lazyload" alt="Salons Ten Boogaerde" /></div>}
              projectnextlink="/projects/logoanimation"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;